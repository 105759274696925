@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-grey-3 font-primary text-base leading-relaxed;
  }
  .section {
    @apply py-[50px] lg:py-[70px];
  }
  .title {
    @apply text-2xl lg:text-[40px] leading-tight font-semibold mb-4;
  }
  .subtitle {
    @apply text-[18px] lg:text-[20px] leading-relaxed mb-5 lg:mb-9;
  }
}
/* In your CSS or SCSS file */
.border-brownish-gold {
  border-color: #daa520; /* Brownish gold color */
}

/* Common styles for all screen sizes */

/* Limit body overflow to hide horizontal scrollbar on small screens */
body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}
